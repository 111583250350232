<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
            <vs-select class="vs-select-small" :value="null" v-model="filter.id_proyek">
              <vs-select-item class="vs-select-small" text="Semua"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
            <vx-input-group class="w-52">
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
          </div>
          <div class="flex items-center">
            <vs-button class="px-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="getData">
              {{ loading.getData ? 'Loading...' : 'Filter' }}
            </vs-button>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
            <QueryEditor v-if="$can('view_query')" code="AR_KWITANSI"/>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <table class="table-fixed table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead class="font-bold">
              <tr class="d-theme-dark-bg">
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-8">#</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-10">Print</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">No. Kwitansi</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">No. Invoice</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-16">Paid Date</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-12">Proyek</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">Customer</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-48">Description</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Total Payment</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.items" :key="item.uuid">
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ index + 1 }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">
                  <vs-checkbox size="small" v-model="item.checked"/>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_kwitansi }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_invoice }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.date }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.kode_proyek }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama_customer }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.description }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_payment | idr }}</td>
              </tr>
            </tbody>
            <tfoot v-if="data.items.length > 0">
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right">{{ summary.grandTotalPayment | idr }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none p-2 rounded-none border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
        <div class="flex flex-wrap items-center justify-end space-x-2">
          <vs-button color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-printer" @click="print" :disabled="checkedItems.length < 1">Print</vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ArRepository from '@/repositories/accounting/ar-repository'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'TabKwitansi',
  props: ['isActive'],
  components: {
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    ImageViewer,
    flatPickr
  },
  mixins: [printoutMixin],
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    checkedItems () {
      return _.filter(this.data.items, item => item.checked)
    },
    summary () {
      return {
        grandTotalPayment: _.sumBy(this.data.items, item => parseFloat(item.total_payment))
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      proyeks: [],
      loading: {
        getData: false
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD'),
        search: null
      },
      data: {
        items: []
      }
    }
  },
  methods: {
    initData () {
      this.getProyek()
      this.isDataInited = true
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.getData = true
      ArRepository.getAccountReceivableKwitansi(this.filter)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.uuid = uuid()
            item.checked = false
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    print () {
      this.showPrintOutFile('AR_KWITANSI', { ids: _.map(this.checkedItems, item => item.id_jurnal) })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
