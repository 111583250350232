<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
            <vs-select class="vs-select-small" :value="null" v-model="filter.id_proyek">
              <vs-select-item class="vs-select-small" text="Semua"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
            <vx-input-group class="w-52">
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="onFilter"/>
          </div>
          <div class="flex items-center">
            <vs-button class="px-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="onFilter">
              {{ loading.getData ? 'Loading...' : 'Filter' }}
            </vs-button>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p class="text-xs font-medium text-dark text-bold">{{ this.totalTransactions }} Result</p>
            <QueryEditor v-if="$can('view_query')" code="AR_LIST"/>
          </div>
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <div class="flex items-center cursor-pointer space-x-1" @click="toggleExpanded">
              <feather-icon :icon="isAllExpanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svgClasses="h-3 w-3"/>
              <span class="text-xs">{{ isAllExpanded ? 'Collapse All' : 'Expand All' }}</span>
            </div>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <table class="table-fixed table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead class="font-bold">
              <tr class="d-theme-dark-bg">
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-20">#</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-16">Tick</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">No. Trx</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Status</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-16">Proyek</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Date</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Due Date</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-48">Customer</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-64">Remark</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-40">Total</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-40">Total Paid</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-40">Total Balance</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">No. Invoice</th>
              </tr>
            </thead>

            <!-----------TABLE BODY------------>
            <tbody v-for="group in data.groups" :key="group.uuid">
              <!--group-->
              <tr class="cursor-pointer hover-bg-primary-transparent-25" @click="group.expanded = !group.expanded">
                <td class="border border-solid d-theme-border-grey-light p-2 font-medium" colspan="13">
                  <div class="flex items-center">
                    <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                    <span class="font-bold text-sm">Group: {{ group.name }}</span>
                  </div>
                </td>
              </tr>
              <!--header-->
              <template v-if="group.expanded">
                <template v-for="header in group.headers">
                  <tr :key="header.uuid" class="cursor-pointer hover-bg-primary-transparent-25" @click="header.expanded = !header.expanded">
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap" colspan="13">
                      <div class="flex items-center ml-5">
                        <feather-icon :icon="header.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                        <span class="font-bold">{{ header.name }}</span>
                      </div>
                    </td>
                  </tr>
                  <!--items-->
                  <template v-if="header.expanded">
                    <tr v-for="(item, index) in header.items" :key="item.uuid" :class="{'bg-success-transparent-10': item.total_paid > 0}">
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">
                        <span class="ml-12">{{ index + 1 }}</span>
                      </td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center" @click="onItemClicked($event, item)">
                        <vs-checkbox size="small" v-if="!item.id_invoice && $can('create_invoice')" v-model="item.checked" :disabled="(item.status !== 'UNPAID' && item.status !== 'PART PAID' && item.status !== 'PAYABLE') || item.id_invoice" @input="onItemChecked($event, item)"/>
                      </td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_trx }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.status }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode_proyek }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.date }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.due_date }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama_customer }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.remark }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_paid | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_balance | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_invoice || '-' }}</td>
                    </tr>
                  </template>
                </template>
                <!--group summary-->
                <tr class="d-theme-dark-bg">
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-center uppercase" colspan="9">Total Group {{ group.name }}</td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right">{{ group.total | idr }}</td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right">{{ group.totalPaid | idr }}</td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right">{{ group.totalBalance | idr }}</td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right"></td>
                </tr>
              </template>
            </tbody>

            <!-----------TABLE FOOT------------>
            <tfoot v-if="totalTransactions > 0">
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right">{{ summary.grandTotal | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right">{{ summary.grandTotalPaid | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right">{{ summary.grandTotalBalance | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none p-2 rounded-none border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
        <div class="flex flex-wrap items-center justify-between">
          <div class="flex">
<!--            <vs-button :disabled="data.itemsChecked.length < 1" color="success" size="small" class="px-3 mr-2" icon-pack="feather" icon="icon-check" @click="showProcessPayment()">Process Payment</vs-button>-->
            <vs-button v-if="$can('create_invoice')" :disabled="data.itemsChecked.length < 1" color="success" size="small" class="px-3 mr-2" icon-pack="feather" icon="icon-book-open" @click="showCreateInvoice()">Create Invoice</vs-button>
          </div>
          <div class="flex justify-end space-x-2">
            <div class="flex items-center">
              <div class="w-4 h-4 rounded-full border border-solid d-theme-border-grey-light"></div>
              <span class="text-xs">Unpaid</span>
            </div>
            <div class="flex items-center">
              <div class="w-4 h-4 rounded-full border border-solid d-theme-border-grey-light bg-success-transparent-50"></div>
              <span class="text-xs">Part Paid</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--modals-->
    <ProcessPayment :isActive.sync="modalProcessPayment.active"
                    :transactions="checkedTransactions"
                    @success="onFilter"/>

    <CreateInvoice :isActive.sync="modalCreateInvoice.active"
                    :transactions="checkedTransactions"
                    @success="onFilter"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ArRepository from '@/repositories/accounting/ar-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'TabArList',
  props: ['isActive'],
  components: {
    ProcessPayment: () => import('@/views/pages/accounting/account-receivable/ar/part/ProcessPayment'),
    CreateInvoice: () => import('@/views/pages/accounting/account-receivable/ar/part/CreateInvoice'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    flatPickr
  },
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    totalTransactions () {
      return _.sumBy(this.data.groups, group => _.sumBy(group.headers, header => header.items.length))
    },
    checkedTransactions () {
      return this.data.itemsChecked
    },
    summary () {
      return {
        grandTotal: _.sumBy(this.data.groups, item => parseFloat(item.total)),
        grandTotalPaid: _.sumBy(this.data.groups, item => parseFloat(item.totalPaid)),
        grandTotalBalance: _.sumBy(this.data.groups, item => parseFloat(item.totalBalance))
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isAllExpanded: true,
      proyeks: [],
      loading: {
        getData: false
      },
      modalProcessPayment: {
        active: false
      },
      modalCreateInvoice: {
        active: false
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD'),
        search: null
      },
      data: {
        groups: [],
        itemsChecked: []
      }
    }
  },
  methods: {
    initData () {
      this.getProyek()
      this.isDataInited = true
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.getData = true
      ArRepository.getAccountReceivableList(this.filter)
        .then(response => {
          this.data.groups = this.buildGroups(_.cloneDeep(response.data.data))
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    buildGroups (data) {
      const generateItems = (headerItems) => {
        return _.map(headerItems, item => {
          return {
            ...item,
            uuid: uuid(),
            id_trx: item.is_group_by_trx_header ? item.id_trx_h : item.id_trx_d,
            no_trx: item.is_group_by_trx_header ? item.no_trx_h : item.no_trx_d,
            checked: false
          }
        })
      }

      const generateHeaders = (groupItems) => {
        return _.chain(groupItems)
          .groupBy(item => `${item.tipe_jurnal} - ${item.id_trx_h}`)
          .map((headerItems, header) => {
            const items = generateItems(headerItems)
            return {
              uuid: uuid(),
              name: headerItems[0].no_trx_h,
              items: items,
              total: _.sumBy(items, item => item.total),
              totalPaid: _.sumBy(items, item => item.total_paid),
              totalBalance: _.sumBy(items, item => item.total_balance),
              expanded: true
            }
          })
          .value()
      }

      const generateGroups = () => {
        return _.chain(data)
          .groupBy(item => item.group)
          .map((groupItems, group) => {
            const headers = generateHeaders(groupItems)
            return {
              uuid: uuid(),
              name: group,
              headers: headers,
              total: _.sumBy(headers, item => item.total),
              totalPaid: _.sumBy(headers, item => item.totalPaid),
              totalBalance: _.sumBy(headers, item => item.totalBalance),
              expanded: true
            }
          })
          .value()
      }

      return generateGroups()
    },

    toggleExpanded () {
      this.isAllExpanded = !this.isAllExpanded
      this.data.groups = _.map(this.data.groups, group => {
        group.expanded = this.isAllExpanded
        group.headers = _.map(group.headers, header => {
          header.expanded = this.isAllExpanded
          return header
        })
        return group
      })
    },

    onItemClicked (event, item) {
      let isSameGroupAndCustomerAndProyek = true
      _.each(this.data.itemsChecked, it => {
        if ((item.group !== it.group) || (item.id_customer !== it.id_customer) || (item.id_proyek !== it.id_proyek)) {
          isSameGroupAndCustomerAndProyek = false
        }
      })
      if (!isSameGroupAndCustomerAndProyek) {
        event.preventDefault()
        this.notifyWarning('Group transaksi, customer, dan proyek yang dipilih harus sama.')
      }
    },

    onItemChecked (isChecked, item) {
      if (isChecked) {
        this.data.itemsChecked.push(item)
      } else {
        const index = _.findIndex(this.data.itemsChecked, it => it.uuid === item.uuid)
        this.data.itemsChecked.splice(index, 1)
      }
    },

    showProcessPayment () {
      this.modalProcessPayment.active = true
    },

    showCreateInvoice () {
      this.modalCreateInvoice.active = true
    },

    onFilter () {
      this.getData()
      this.data.itemsChecked = []
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
