import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/ar'

export default {
  getAccountReceivableList (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/accountReceivableList${queryParams}`)
  },

  getAccountReceivablePaid (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/accountReceivablePaid${queryParams}`)
  },

  getAccountReceivableKwitansi (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/accountReceivableKwitansi${queryParams}`)
  },

  getProcessPaymentTransactions (params) {
    return httpClient.post(`${endpoint}/processPaymentTransactions`, params)
  },

  processPayment (params) {
    return httpClient.post(`${endpoint}/processPayment`, params)
  },

  getInvoice (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/invoice${queryParams}`)
  },

  showInvoice (idInvoice) {
    return httpClient.get(`${endpoint}/invoice/${idInvoice}`)
  },

  createInvoice (params) {
    return httpClient.post(`${endpoint}/invoice`, params)
  },

  getPaymentHistory (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/paymentHistory${queryParams}`)
  }
}
