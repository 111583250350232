import _ from 'lodash'

const errors = {
  global: {
    isError: false,
    messages: null
  },
  tabCustomer: {
    isError: false,
    messages: null
  },
  tabKontrak: {
    isError: false,
    messages: null
  },
  tabAngsuran: {
    isError: false,
    messages: null
  }
}

const getDefaultState = () => {
  return {
    // global
    activeTab: 0,
    errors: errors,
    isAnyUnsavedChanges: false,

    // tab ar list
    tabArList: {},

    // tab invoice
    tabInvoice: {},

    // tab ar paid
    tabArPaid: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_ERRORS (state, payload) {
      const errors = payload
      const globalErrors = errors
      const tabArListErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabAeList'))
      const tabInvoiceErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabInvoice'))
      const tabArPaidErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabAePaid'))

      state.errors.global.messages = globalErrors
      state.errors.tabArList.messages = tabArListErrors
      state.errors.tabInvoice.messages = tabInvoiceErrors
      state.errors.tabArPaid.messages = tabArPaidErrors

      state.errors.global.isError = !_.isEmpty(globalErrors)
      state.errors.tabArList.isError = !_.isEmpty(tabArListErrors)
      state.errors.tabInvoice.isError = !_.isEmpty(tabInvoiceErrors)
      state.errors.tabArPaid.isError = !_.isEmpty(tabArPaidErrors)
    },
    CLEAR_ERRORS (state) {
      state.errors = _.cloneDeep(errors)
    },
    SET_ANY_UNSAVED_CHANGES (state, isAnyUnsavedChanges) {
      state.isAnyUnsavedChanges = isAnyUnsavedChanges
    },
    SET_TAB_AR_LIST (state, payload) {
      state.tabArList = payload
    },
    SET_TAB_INVOICE (state, payload) {
      state.tabInvoice = payload
    },
    SET_TAB_AR_PAID (state, payload) {
      state.tabArPaid = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
