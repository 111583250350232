<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light">
    <!--tabs-->
    <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="p-2 vx-card shadow-none rounded-none">
        <div class="flex h-auto">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tab, index) in tabs.items"
                :key="index"
                :class="{'text-primary font-bold bg-primary-transparent-25': tabs.active === tab.code, 'hover-bg-primary-transparent-25': tabs.active !== tab.code}"
                @click="tabs.active = tab.code"
                class="cursor-pointer px-2 py-1 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-t-0 border-b-0 border-l-0 border-r">
              {{ tab.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--content-->
    <div class="flex-grow">
      <div class="h-full d-theme-dark-bg">
        <TabArList v-show="tabs.active === 'AR_LIST'" :is-active="tabs.active === 'AR_LIST'"/>
        <TabInvoice v-show="tabs.active === 'INVOICES'" :is-active="tabs.active === 'INVOICES'"/>
        <TabArPaid v-show="tabs.active === 'AR_PAID'" :is-active="tabs.active === 'AR_PAID'"/>
        <TabKwitansi v-show="tabs.active === 'KWITANSI'" :is-active="tabs.active === 'KWITANSI'"/>
      </div>
    </div>
  </div>
</template>

<script>
import moduleAr from '@/store/modules/accounting/ar/ar.store'
import TabArList from '@/views/pages/accounting/account-receivable/ar/tabs/TabArList'
import TabInvoice from '@/views/pages/accounting/account-receivable/ar/tabs/TabInvoices'
import TabArPaid from '@/views/pages/accounting/account-receivable/ar/tabs/TabArPaid'
import TabKwitansi from '@/views/pages/accounting/account-receivable/ar/tabs/TabKwitansi'

export default {
  name: 'AccountReceivable',
  components: {
    TabKwitansi,
    TabArList,
    TabInvoice,
    TabArPaid
  },
  data () {
    return {
      tabs: {
        active: 'AR_LIST',
        items: [
          { code: 'AR_LIST', title: 'A/R List' },
          { code: 'INVOICES', title: 'Invoices' },
          { code: 'AR_PAID', title: 'A/R Paid' },
          { code: 'KWITANSI', title: 'Kwitansi' }
        ]
      }
    }
  },
  beforeCreate () {
    this.$store.registerModule(['accounting', 'ar'], moduleAr)
  },
  beforeDestroy () {
    this.$store.commit('accounting/ar/RESET_STATE')
    this.$store.unregisterModule(['accounting', 'ar'])
  }
}
</script>
